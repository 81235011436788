import distanceToPositionSort from "./distanceToPositionSort";
import numericalSort from "../components/HouseholdSelect/helpers/numericalSort";
import oddSort from "../components/HouseholdSelect/helpers/oddSort";
import evenSort from "../components/HouseholdSelect/helpers/evenSort";

const sorts = {
  distance: distanceToPositionSort,
  numerical: numericalSort,
  odd: oddSort,
  even: evenSort
};
export const sortHouseholds = ({
  households,
  sortType,
  sortAscending,
  position
}) => {
  if (!households) return [];
  return households.sort(
    sorts[sortType](sortType === "distance" ? position : sortAscending)
  );
};
