import "./FlowSelect.css";
import React from "react";
import Button from "../common/Button";
import Page from "../app/Page";
import { useNavigate } from "react-router-dom";
import {
  getHadLocationPermissionBefore,
  setFLowType
} from "../../helpers/localStorage";

const FlowSelect = () => {
  const navigate = useNavigate();

  return (
    <Page header={"Select a search method"} backButton={false}>
      <Button
        onClick={() => {
          setFLowType("gps");
          navigate(
            getHadLocationPermissionBefore() ? "/streets" : "/permissions"
          );
        }}
        className="street-select-button"
      >
        GPS
      </Button>
      <Button
        onClick={() => {
          setFLowType("search");
          navigate("/addressSearch");
        }}
        className="street-select-button"
        type="reset"
      >
        Search Manually
      </Button>
    </Page>
  );
};

export default FlowSelect;
