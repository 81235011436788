import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./BreadCrumb.css";
import propTypes from "./BreadCrumb.propTypes";
import { getFlowType } from "../../../../helpers/localStorage";

/**
 * Breadcrumbs uses the router to keep track of the paths.
 * The root path street or addressSearch is added to the path as it is lost when navigating from streets (gps) to street/*
 */

const BreadCrumb = ({ display = false }) => {
  const location = useLocation();

  const breadcrumbContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the end of the breadcrumb container
    if (breadcrumbContainerRef.current) {
      breadcrumbContainerRef.current.scrollLeft =
        breadcrumbContainerRef.current.scrollWidth;
    }
  }, [location]);

  const flowType = getFlowType();

  const FLOW_TYPE = {
    gps: flowType === "gps",
    search: flowType === "search"
  };

  const streetPagePath = {
    label: "Streets",
    path: "/Streets"
  };

  const addressSearchPath = {
    label: "Address Search",
    path: "/addressSearch"
  };

  const titleCase = (str) =>
    str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

  const pathParts = location.pathname.split("/");

  let breadCrumbParts = pathParts
    .reduce((acc, path, index) => {
      return [
        ...acc,
        {
          path: pathParts.slice(0, index + 1).join("/"),
          label: titleCase(decodeURIComponent(path))
        }
      ];
    }, [])
    .filter(({ label }) => label)
    .slice(1);

  // The Town route is removed because it does not related to a page.
  if (FLOW_TYPE.gps) {
    breadCrumbParts = breadCrumbParts.slice(1);
  }

  return (
    <section ref={breadcrumbContainerRef} className={"breadCrumbContainer"}>
      {display &&
        [
          FLOW_TYPE.gps ? streetPagePath : addressSearchPath,
          ...breadCrumbParts
        ].map(({ label, path }) => (
          <span key={path} className={"breadCrumbItem"}>
            <Link className={"breadCrumbLink"} to={path}>
              {label}
            </Link>
            <Link className={"breadCrumbSeparator"} to={path} />
            {/*{index !== breadCrumbParts.length && <span>&raquo;</span>}*/}
          </span>
        ))}
    </section>
  );
};

BreadCrumb.propTypes = propTypes;

export default BreadCrumb;
