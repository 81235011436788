import PropTypes from "prop-types";

const propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  onSkipClick: PropTypes.func.isRequired,
  existingContactDetails: PropTypes.exact({
    email: PropTypes.string,
    telephone: PropTypes.string,
    mobile: PropTypes.string
  }),
  includeCaseTypeSelect: PropTypes.bool
};

export default propTypes;
