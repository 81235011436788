/**
 * Joins multiple string classnames together
 *  @param {string}  dob - date string passed as argument
 *  @returns {string} - returns age of the paerson based on dob string
 */

export const getAge = (dob) => {
  if (!dob || dob == "0000-00-00") return "";
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getDBdateFormat = (date = new Date()) => {
  const addZero = (val) => (val < 10 ? "0" + val : val);
  date = new Date();
  let yyyy = date.getFullYear();
  let mm = addZero(date.getMonth() + 1);
  let dd = addZero(date.getDate());

  return yyyy + "-" + mm + "-" + dd + " " + "00:00:00";
};

export const getDateForDisplay = (timestamp) => {
  let ts = timestamp.split(" ")[0].split("-");
  return ts[2] + "/" + ts[1] + "/" + ts[0];
};

export const UTCtolocalDateFromDB = (dateTimeString) => {
  const [dateStr, timeStr] = dateTimeString.split(" ");
  const [year, month, day] = dateStr.split("-").map(Number);
  let hour = "00";
  let min = "00";
  let sec = "00";
  if (timeStr) {
    [hour, min, sec] = timeStr.split(":").map(Number);
  }
  return new Date(Date.UTC(year, month - 1, day, hour, min, sec));
};

/**
 *  Converts UTC date string from backend to local date time object.
 *  @param {string}  date - date string passed as argument
 *  @returns {Object<Date>} - returns local date formatted for display
 */
export const utcDateToLocalDate = (date) => {
  if (!date) return new Date();

  date = new Date(date);

  const localDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  localDate.setHours(hours - offset);

  return localDate;
};

/**
 *  Formats date object for display.
 *  @param {Object<Date>}  date - date string passed as argument
 *  @returns {string} - returns date as a formatted string dd/MM/yyyy
 */
export const formatDate = (date) => {
  if (!date) return null;

  const formatter = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  });

  const parts = formatter.formatToParts(date);

  const { year, month, day } = parts.reduce((acc, cur) => {
    acc[cur.type] = cur.value;
    return acc;
  }, {});

  return `${day}/${month}/${year}`;
};
