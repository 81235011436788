import { UTCtolocalDateFromDB } from "helpers/dates";

const today = new Date();

const pad = (num) => num.toString().padStart(2, "0");

const isToday = (date) =>
  today.getDate() === date.getDate() &&
  today.getMonth() === date.getMonth() &&
  today.getFullYear() === date.getFullYear();

const isYesterday = (date) =>
  today.getDate() - 1 === date.getDate() &&
  today.getMonth() === date.getMonth() &&
  today.getFullYear() === date.getFullYear();

const getVisitedType = (visited, iln) => {
  switch (true) {
    case isToday(visited):
      return `${iln.gettext("today")} ${pad(visited.getHours())}:${pad(
        visited.getMinutes()
      )}`;
    case isYesterday(visited):
      return `${iln.gettext("yesterday")} ${pad(visited.getHours())}:${pad(
        visited.getMinutes()
      )}`;
    default:
      return `${pad(visited.getDate())}/${pad(visited.getMonth())}/${pad(
        visited.getFullYear().toString().substr(-2)
      )} `;
  }
};

export default function getLastVisitedString(dateString, iln) {
  const visited = UTCtolocalDateFromDB(dateString);
  return `${iln.gettext("Visited")} ${getVisitedType(visited, iln)}`;
}
