import "./styles.css";

import ErrorText from "components/common/ErrorText";
import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const TextArea = ({
  name,
  className,
  errorText,
  keepErrorSpacing,
  ...props
}) => {
  return (
    <React.Fragment>
      <textarea
        name={name}
        role="input"
        className={classnames(
          "textArea",
          errorText && "textArea-has-error",
          className?.input
        )}
        {...props}
      />
      {(errorText || keepErrorSpacing) && (
        <ErrorText className={className?.error}>{errorText}</ErrorText>
      )}
    </React.Fragment>
  );
};

TextArea.propTypes = propTypes;

export default TextArea;
