import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";

const CustomCheckbox = ({ name, checked, label }) => {
  return (
    <React.Fragment>
      <div className="checkboxContainer">
        <div className={classnames("checkbox", checked ? "checked" : "")}></div>
        {label && (
          <label htmlFor={`${name}-checkbox`} className="label">
            {label}
          </label>
        )}
      </div>
    </React.Fragment>
  );
};

CustomCheckbox.propTypes = propTypes;

export default CustomCheckbox;
