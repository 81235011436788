import "./Dropdown.css";

import React from "react";
import propTypes from "./Dropdown.propTypes";
import Button from "../../Button";
import ButtonList from "../../ButtonList";

const Dropdown = ({ items, open, onItemSelected }) => {
  if (!open) return null;

  return (
    <ButtonList className="dropdown-container" role="list">
      {items.map((item, index) => {
        return (
          <Button
            onClick={() => onItemSelected(item)}
            key={index}
            className="dropdown-item"
          >
            {item.text}
          </Button>
        );
      })}
    </ButtonList>
  );
};

Dropdown.propTypes = propTypes;
export default Dropdown;
