import "./styles.css";

import React, { useContext, useState } from "react";

import Input from "components/common/Input";
import { TranslationContext } from "context/Translation";
import { isEmail } from "helpers/isEmail";
import propTypes from "./ContactDetailForm.propTypes";

const validPhoneValuesOnly = (value) =>
  value.replace(new RegExp("[^0-9+() -]"), "");

const ContactDetailForm = ({ state, setState }) => {
  const iln = useContext(TranslationContext);

  const [emailError, setEmailError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [mobileError, setMobileError] = useState("");

  return (
    <React.Fragment>
      <div className="Label">{iln.gettext("Email")}</div>
      <Input
        name="email"
        value={state.email}
        errorText={emailError}
        onChange={({ target: { name, value } }) => {
          setState({ ...state, [name]: value });
          value !== "" && !isEmail(value)
            ? setEmailError("Please enter valid email address")
            : setEmailError("");
        }}
        keepErrorSpacing
        className={{ error: "Email-collection-error" }}
      />
      <div className="Label">{iln.gettext("Telephone")}</div>
      <Input
        name="telephone"
        value={state.telephone}
        errorText={telephoneError}
        onChange={({ target: { name, value } }) => {
          setState({ ...state, [name]: validPhoneValuesOnly(value) });
          setTelephoneError("");
        }}
        keepErrorSpacing
        className={{ error: "Email-collection-error" }}
      />
      <div className="Label">{iln.gettext("Mobile")}</div>
      <Input
        name="mobile"
        value={state.mobile}
        errorText={mobileError}
        onChange={({ target: { name, value } }) => {
          setState({ ...state, [name]: validPhoneValuesOnly(value) });
          setMobileError("");
        }}
        keepErrorSpacing
        className={{ error: "Email-collection-error" }}
      />
    </React.Fragment>
  );
};

ContactDetailForm.propTypes = propTypes;

export default ContactDetailForm;
