import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.node,
  breadCrumb: PropTypes.bool
};

export default propTypes;
