import "./Select.styles.css";

import ErrorText from "components/common/ErrorText";
import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./Select.propTypes";

const Select = ({
  children,
  className,
  errorText,
  keepErrorSpacing,
  ...props
}) => {
  return (
    <React.Fragment>
      <select
        {...props}
        role="select"
        className={classnames(
          "Select",
          errorText && "Select-has-error",
          className?.select
        )}
      >
        {children}
      </select>
      {(errorText || keepErrorSpacing) && (
        <ErrorText className={className?.error}>{errorText}</ErrorText>
      )}
    </React.Fragment>
  );
};

Select.propTypes = propTypes;

export default Select;
