import PropTypes from "prop-types";

const propTypes = {
  state: PropTypes.exact({
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired
  }),
  setState: PropTypes.func.isRequired
};

export default propTypes;
