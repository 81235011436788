import "./styles.css";

import { Link, useNavigationType, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  getSortOptions,
  setSelectedStreet,
  setSelectedTown
} from "helpers/localStorage";

import Button from "components/common/Button";
import ButtonBar from "components/common/ButtonBar/ButtonBar";
import ChangeSortButton from "./ChangeSortButton";
import Household from "components/Household";
import HouseholdList from "components/HouseholdSelect/HouseholdList/HouseholdList";
import HouseholdPlaceholder from "components/HouseholdSelect/HouseholdPlaceholder/HouseholdPlaceholder";
import Map from "components/common/Map";
import Page from "components/app/Page";
import Spinner from "components/common/Spinner";
import { TranslationContext } from "context/Translation";
import api from "api";
import propTypes from "./propTypes";
import { useReducer } from "react";
import { sortHouseholds } from "../../helpers/sortHouseholds";

const sortOptions = getSortOptions() || { type: "distance", ascending: true };

const getIconColor = (lastVisited, visited) => {
  const visitedDate = lastVisited && new Date(lastVisited);

  const withinLastWeek = () => {
    const today = new Date();
    const LastWeek = new Date().setDate(today.getDate() - 7);
    return visitedDate > LastWeek;
  };
  const withinLastYear = (visitedDate) => {
    const today = new Date();
    const oneYearAgo = new Date().setFullYear(today.getFullYear() - 1);
    return visitedDate > oneYearAgo;
  };
  return !visited
    ? "blue"
    : withinLastWeek(visitedDate)
    ? "green"
    : withinLastYear(visitedDate)
    ? "orange"
    : "blue";
};

const HouseholdSelectPage = ({ position }) => {
  const iln = useContext(TranslationContext);

  const { streetName, town, "*": houseNumber } = useParams();
  const [households, setHouseholds] = useState([]);
  const [household, setHousehold] = useState();
  const [mapPins, setMapPins] = useState([]);
  const [sortType, setSortType] = useState(sortOptions.type);
  const [sortAscending, setSortAscending] = useState(sortOptions.ascending);
  const [loading, setLoading] = useState(true);
  const [view, toggleView] = useReducer(
    (state) => (state === "list" ? "map" : "list"),
    "list"
  );
  setSelectedStreet(streetName);
  setSelectedTown(town);

  const navigationType = useNavigationType();

  /**
   * If the user navigates to this page load all data.
   * This handles the user coming back from the household page.
   */
  useEffect(() => {
    if (!streetName || !town) return;
    setLoading(true);
    api
      .getNearbyHouseholds(position)
      .then(({ households }) => setMapPins([...households]));

    api
      .getHouseholds({ streetName, town })
      .then(({ households }) => {
        setHouseholds([...households]);
      })
      .catch((e) => {
        setHousehold([]);
        throw new Error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigationType]);

  useEffect(() => {
    view === "map" &&
      api
        .getNearbyHouseholds(position)
        .then(({ households }) => setMapPins([...households]));
  }, [position]);

  useEffect(() => {
    if (houseNumber && households?.length > 0) {
      let householdData = view === "map" ? [...mapPins] : [...households];
      setHousehold(
        householdData.find(({ address: { address1, address2 } }) =>
          address2
            ? address1 + " " + address2 === houseNumber
            : address1 === houseNumber
        )
      );
    }
  }, [houseNumber, households]);

  if (loading) {
    return (
      <Page header={streetName}>
        <HouseholdPlaceholder />
      </Page>
    );
  }

  if (household && houseNumber) {
    return (
      <Page header={houseNumber} breadCrumb>
        <Household
          households={sortHouseholds({
            households,
            sortType,
            sortAscending,
            position
          })}
          household={household}
        />
      </Page>
    );
  }

  return (
    <Page header={streetName} breadCrumb>
      <ButtonBar>
        <Button onClick={toggleView}>
          {view === "list"
            ? iln.gettext("Show on Map")
            : iln.gettext("Show as List")}
        </Button>
        {view === "list" && (
          <ChangeSortButton
            setSortType={setSortType}
            setSortAscending={setSortAscending}
          />
        )}
      </ButtonBar>
      {view === "list" ? (
        <HouseholdList
          households={sortHouseholds({
            households,
            sortType,
            sortAscending,
            position
          })}
          streetName={streetName}
          position={position}
        />
      ) : (
        <div id="mapContainer">
          {mapPins.length == 0 && (
            <div id="mapSpinnerOverLay">
              <Spinner size="large" />
            </div>
          )}
          <Map
            height="calc(100vh - 300px)"
            position={position}
            markers={mapPins.map(
              ({
                lat,
                lng,
                lastVisited,
                visited,
                address: { address1, address2 }
              }) => ({
                lat,
                lng,
                icon: "house",
                color: getIconColor(lastVisited, visited),
                popup: (
                  <Link
                    to={`/street/${town}/${streetName}/${
                      address2 ? address1 + " " + address2 : address1
                    }`}
                    state={{ from: window.location.pathname }}
                  >
                    {address1}
                  </Link>
                )
              })
            )}
          />
        </div>
      )}
    </Page>
  );
};

HouseholdSelectPage.propTypes = propTypes;

export default HouseholdSelectPage;
