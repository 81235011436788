import { getLocale } from "helpers/localStorage";

const locale = getLocale();

// Remove all spaces, dashes, and parentheses for simplicity
const removeCharsRegex = /\s|-|\(.*?\)/g;

const phoneNumberToIntlFormat = (phoneNumber = "", countryCode) => {
  phoneNumber = phoneNumber.replace(removeCharsRegex, "");

  if (phoneNumber.startsWith(countryCode)) return phoneNumber;

  // Add the country code at the start
  if (phoneNumber.startsWith("0"))
    return countryCode + phoneNumber.substring(1);
  else return countryCode + phoneNumber;
};

const phoneNumberToLocalFormat = (
  phoneNumber = "",
  countryCode,
  prependZero
) => {
  phoneNumber = phoneNumber.replace(removeCharsRegex, "");

  // If the number starts with the country code, it's international, so convert to local
  if (phoneNumber.startsWith(countryCode)) {
    // Remove the country code
    return phoneNumber.replace(countryCode, prependZero ? "0" : "");
  }

  return phoneNumber;
};

export const mobileNumberToLocal = (value) => {
  switch (locale) {
    // Australia
    case "en_AU": {
      const convertedValue = phoneNumberToLocalFormat(value, "+61", true);
      const formattedValue = `${convertedValue.substring(
        0,
        4
      )} ${convertedValue.substring(4, 7)} ${convertedValue.substring(7)}`;

      return formattedValue;
    }

    // Great Britain
    case "en_GB": {
      const convertedValue = phoneNumberToLocalFormat(value, "+44", true);
      const formattedValue = `${convertedValue.substring(
        0,
        5
      )} ${convertedValue.substring(5, 8)} ${convertedValue.substring(8)}`;

      return formattedValue;
    }

    // Cayman Islands
    case "en_KY": {
      const convertedValue = phoneNumberToLocalFormat(value, "+1345", false);
      const formattedValue = `${convertedValue.substring(
        0,
        3
      )} ${convertedValue.substring(3)}`;

      return formattedValue;
    }

    default: {
      return value;
    }
  }
};

export const telephoneNumberToLocal = (value) => {
  switch (locale) {
    // Australia
    case "en_AU": {
      const convertedValue = phoneNumberToLocalFormat(value, "+61", true);
      const formattedValue = `${convertedValue.substring(
        0,
        2
      )} ${convertedValue.substring(2, 6)} ${convertedValue.substring(6)}`;

      return formattedValue;
    }

    // Great Britain
    case "en_GB": {
      const convertedValue = phoneNumberToLocalFormat(value, "+44", true);
      const formattedValue = `${convertedValue.substring(
        0,
        5
      )} ${convertedValue.substring(5)}`;

      return formattedValue;
    }

    // Cayman Islands
    case "en_KY": {
      return phoneNumberToLocalFormat(value, "+1345", false);
    }

    default: {
      return value;
    }
  }
};

export const phoneNumberToInternational = (value) => {
  switch (locale) {
    // Australia
    case "en_AU": {
      return phoneNumberToIntlFormat(value, "+61");
    }

    // Great Britain
    case "en_GB": {
      return phoneNumberToIntlFormat(value, "+44");
    }

    // Cayman Islands
    case "en_KY": {
      return phoneNumberToIntlFormat(value, "+1");
    }

    default: {
      return value;
    }
  }
};
