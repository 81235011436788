import "./styles.css";

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "components/common/Button";
import ErrorText from "components/common/ErrorText";
import { ModalContext } from "context/Modal";
import Page from "components/app/Page";
import Spinner from "components/common/Spinner";
import { TranslationContext } from "context/Translation";
import api from "api";
import { clearSurveyResults, getFlowType } from "helpers/localStorage";
import propTypes from "./propTypes";
import { useContext } from "react";

const SurveyComplete = ({ message, constituentID, surveyID, results }) => {
  const iln = useContext(TranslationContext);
  const { modalActions } = useContext(ModalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const submit = () => {
    setLoading(true);
    setError();
    api
      .submitSurveyResults({ constituentID, surveyID, results })
      .then(() => {
        setSuccess(true);
        clearSurveyResults();
        setTimeout(() => {
          navigate(
            location.state?.from || getFlowType() === "gps"
              ? "/streets"
              : "/addressSearch"
          );
          modalActions.close();
        }, 5000);
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <Page className="Survey-complete-page">
      <h2>{message}</h2>
      <p>
        {iln.gettext("End of survey, click")}{" "}
        <strong>{iln.gettext("Submit")}</strong>{" "}
        {iln.gettext("below to complete.")}
      </p>
      <Button onClick={submit} disabled={loading || success}>
        {loading ? <Spinner /> : iln.gettext("Submit")}
      </Button>
      {error && (
        <ErrorText>{`${error?.message}, ${iln.gettext(
          "please try again."
        )}`}</ErrorText>
      )}
      {success && <p>{iln.gettext("Upload successful, redirecting...")}</p>}
    </Page>
  );
};

SurveyComplete.propTypes = propTypes;

export default SurveyComplete;
