import "./styles.css";

import React from "react";
import classnames from "helpers/classnames";
import propTypes from "./propTypes";
import BreadCrumb from "./BreadCrumb/BreadCrumb";

const Page = ({ children, className, breadCrumb }) => {
  return (
    <React.Fragment>
      <div className={classnames("Page", className)}>
        <BreadCrumb display={breadCrumb} />
        {children}
      </div>
    </React.Fragment>
  );
};

Page.propTypes = propTypes;

export default Page;
