import "./styles.css";

import React, { useContext, useState } from "react";

import Button from "components/common/Button";
import ButtonList from "components/common/ButtonList";
import CustomCheckbox from "components/common/CustomCheckbox";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";

/*eslint-disable*/
const MultipleChoice = ({ onConfirmClick, onBackClick, answers }) => {
  const [selected, setSelected] = useState([]);
  const iln = useContext(TranslationContext);
  return (
    <div className="Single-choice-question">
      <ButtonList>
        {answers.map(({ text, answerID }) => (
          <div key={answerID}>
            <Button
              className="checkboxButton"
              onClick={() => {
                setSelected(
                  !selected.includes(answerID)
                    ? [...selected, answerID]
                    : selected.filter((s) => s !== answerID)
                );
              }}
            >
              <CustomCheckbox
                name={`${answerID}`}
                label={text}
                checked={selected.includes(answerID)}
              />
            </Button>
          </div>
        ))}
      </ButtonList>
      <div className="Buttons-wrapper">
        {onBackClick ? (
          <Button className="Back-button" onClick={onBackClick}>
            {iln.gettext("Back")}
          </Button>
        ) : (
          <div />
        )}
        <Button
          className="Back-button"
          disabled={selected.length === 0}
          onClick={() => onConfirmClick(selected)}
        >
          {iln.gettext("Confirm")}
        </Button>
      </div>
    </div>
  );
};

MultipleChoice.propTypes = propTypes;

export default MultipleChoice;
