import "./styles.css";

import React, { useContext, useState } from "react";

import Button from "components/common/Button";
import { DebugModeContext } from "context/DebugMode/DebugMode";
import { clearToken, getToken } from "helpers/localStorage";
import propTypes from "./propTypes";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../context/Modal";

const Footer = ({ position }) => {
  const navigate = useNavigate();
  const [touchStart, setTouchStart] = useState();
  const { modalActions } = useContext(ModalContext);
  const { debug, toggleDebug } = useContext(DebugModeContext);

  const handleTouchStart = () => {
    setTouchStart(new Date());
  };

  const handleTouchEnd = () => {
    if (new Date() - touchStart > 1000) {
      toggleDebug();
    }
  };

  const handleLogout = () => {
    modalActions.open({
      title: "Confirm logout",
      component: (
        <div>
          <p>Are you sure you want to logout?</p>
          <div className={"logout-button-container"}>
            <Button
              onClick={() => {
                modalActions.close();
                clearToken();
                navigate("/login");
              }}
            >
              Yes
            </Button>
            <Button onClick={() => modalActions.close()}>Cancel</Button>
          </div>
        </div>
      )
    });
  };

  if (!getToken()) return null;

  return (
    <footer
      onMouseDown={() => handleTouchStart()}
      onMouseUp={() => handleTouchEnd()}
      className="App-footer"
    >
      {position && debug && (
        <div>
          `lat: ${position.lat} lng: ${position.lng}`
        </div>
      )}
      <Button
        className="Logout-button"
        type="reset"
        onClick={() => handleLogout()}
      >
        Logout
      </Button>
    </footer>
  );
};

Footer.propTypes = propTypes;

export default Footer;
