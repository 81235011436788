import "./InputSearchAutoComplete.css";

import React, { useEffect, useState } from "react";

import Button from "components/common/Button";
import Dropdown from "./Dropdown/Dropdown";
import ErrorText from "components/common/ErrorText";
import Spinner from "components/common/Spinner";
import classnames from "helpers/classnames";
import propTypes from "./InputSearchAutoComplete.propTypes";

let controller;

const InputSearchAutoComplete = ({
  name,
  className,
  keepErrorSpacing,
  dataSource,
  onItemSelected,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorText, setErrorText] = useState("");
  const [awaitingResults, setAwaitingResults] = useState(false);
  const [items, setItems] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    setOpenDropdown(items.length > 0);
  }, [items]);

  useEffect(() => {
    controller && controller.abort();
    const debounceHandler = setTimeout(() => {
      fetchResults(inputValue);
    }, 300);

    return () => {
      clearTimeout(debounceHandler);
    };
  }, [inputValue]);

  const fetchResults = async (searchTerm) => {
    controller = new AbortController();
    if (searchTerm.length < 3) return;
    setAwaitingResults(true);
    try {
      const items = await dataSource(searchTerm, controller.signal);
      setAwaitingResults(false);
      setItems(items);
    } catch ({ request }) {
      setAwaitingResults(false);
      setItems([]);
      switch (request?.status) {
        case 404: {
          setErrorText("No matching addresses found");
          return;
        }
        default: {
          setErrorText("There was a error while retrieving households");
        }
      }
    }
  };

  return (
    <div
      className={classnames(
        "Input-search-autocomplete-container",
        className?.container
      )}
    >
      <input
        value={inputValue}
        onFocus={() => setInputValue("")}
        onBlur={() => {
          setTimeout(() => {
            setOpenDropdown(false);
          }, 200);
        }}
        onChange={(e) => {
          setItems([]);
          setErrorText("");
          setInputValue(e.target.value);
        }}
        name={name}
        role="input"
        className={classnames(
          "Input",
          "Input-search-autocomplete",
          errorText && "Input-has-error",
          className?.input
        )}
        {...props}
      />
      {awaitingResults && (
        <Button onClick={() => {}} className={"awaiting-results-container"}>
          <Spinner size="medium" />
        </Button>
      )}
      <Dropdown
        open={openDropdown}
        items={items}
        onItemSelected={(item) => {
          onItemSelected(item);
          setItems([]);
        }}
      />
      {(errorText || keepErrorSpacing) && (
        <ErrorText className={className?.error}>{errorText}</ErrorText>
      )}
    </div>
  );
};

InputSearchAutoComplete.propTypes = propTypes;

export default InputSearchAutoComplete;
