import "./styles.css";

import React, { useContext, useState } from "react";
import { UTCtolocalDateFromDB, formatDate, getAge } from "helpers/dates";
import {
  clearCurrentSurveyQuestion,
  clearSurveyResults,
  getFlowType,
  setSelectedGHousehold,
  setVoter
} from "helpers/localStorage";
import { useLocation, useNavigate } from "react-router-dom";

import Alert from "components/common/Alert";
import Button from "components/common/Button";
import ButtonBar from "components/common/ButtonBar/ButtonBar";
import ButtonList from "components/common/ButtonList";
import ButtonSpinner from "components/common/ButtonSpinner/ButtonSpinner";
import HouseholdButton from "../HouseholdSelect/HouseholdButton";
import List from "components/common/List";
import { TranslationContext } from "context/Translation";
import api from "api";
import propTypes from "./propTypes";

const getHouseholdIndex = (households, toMatch) =>
  households.findIndex(({ address }) => {
    return (
      address.address1 === toMatch.address1 &&
      address.address2 === toMatch.address2 &&
      address.town === toMatch.town &&
      address.postcode === toMatch.postcode &&
      address.state === toMatch.state
    );
  });

const getNeighbouringHouseholds = (households, selectedHouseholdIndex) =>
  [
    households[selectedHouseholdIndex - 3],
    households[selectedHouseholdIndex - 2],
    households[selectedHouseholdIndex - 1],
    households[selectedHouseholdIndex + 1],
    households[selectedHouseholdIndex + 2],
    households[selectedHouseholdIndex + 3]
  ].filter((x) => x);

const HouseholdPage = ({ households, household }) => {
  const {
    constituents: voters,
    lastVisited,
    address,
    address: { address1, address2, town, streetName, postcode, state },
    answered,
    visited
  } = household;

  const iln = useContext(TranslationContext);
  const [submitted, setSubmitted] = useState(false);
  const [noAnwerButtonLoading, setNoAnwerButtonLoading] = useState(false);
  const [refusedButtonLoading, setRefusedButtonLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const householdIndex = getHouseholdIndex(households, address);

  const getVoterLinkForFlowType = ({
    town,
    streetName,
    address1,
    address2,
    firstname,
    surname
  }) => {
    const flowType = getFlowType();

    if (flowType === "gps") {
      return `/street/${town}/${streetName}/${
        address2 ? address1 + " " + address2 : address1
      }/${firstname} ${surname}`;
    }

    if (flowType === "search") {
      return `/addressSearch/${
        address2 ? address1 + " " + address2 : address1
      }/${firstname} ${surname}`;
    }

    throw new Error(
      "No flow type set, needed for navigation: Check flowType in local storage"
    );
  };

  const NameWithVI = (name, dob, gender) => {
    let VI = "";
    //eslint-disable-next-line
    voters.map(({ firstname, surname, votingIntentions }) => {
      if (votingIntentions.length > 0 && firstname + " " + surname === name) {
        const { flag, timestamp } = votingIntentions[0];
        VI = `(${flag} - ${formatDate(UTCtolocalDateFromDB(timestamp))})`;
      }
    });
    return (
      <div>
        <span>{`${name} - ${getAge(dob)}${gender}`}</span>
        {VI && (
          <div>
            <br />
            <span className="Household-VI">{VI}</span>
          </div>
        )}
      </div>
    );
  };

  const handleDidNotAnswer = () => {
    setNoAnwerButtonLoading(true);
    setSubmitted(true);
    api.didNotAnswer(address1, address2, town, postcode, state).then(() => {
      setNoAnwerButtonLoading(false);
    });
  };

  const handleRefused = () => {
    setRefusedButtonLoading(true);
    setSubmitted(true);
    api
      .didNotParticipateHouseHold(address1, address2, town, postcode, state)
      .then(() => {
        setRefusedButtonLoading(false);
      });
  };

  return (
    <React.Fragment>
      {(visited || submitted || answered) && (
        <Alert>{`Household has been visited`}</Alert>
      )}
      <ButtonBar>
        <Button onClick={() => handleDidNotAnswer()}>
          {noAnwerButtonLoading ? <ButtonSpinner /> : iln.gettext("No Answer")}
        </Button>
        <Button onClick={() => handleRefused()}>
          {refusedButtonLoading ? <ButtonSpinner /> : iln.gettext("Refused")}
        </Button>
      </ButtonBar>
      <List
        label={iln.gettext("Last Visited")}
        items={[
          lastVisited ? formatDate(UTCtolocalDateFromDB(lastVisited)) : ""
        ]}
      />
      <h1>{iln.gettext("Registered Voters")}</h1>
      <ButtonList>
        {voters.map(
          (
            {
              id,
              firstname,
              surname,
              dob,
              gender,
              votingIntentions,
              mobile,
              email,
              telephone
            },
            idx
          ) => (
            <Button
              key={idx}
              className={"Household-button"}
              onClick={() => {
                setVoter({
                  id,
                  firstname,
                  surname,
                  dob,
                  gender,
                  votingIntentions,
                  mobile,
                  email,
                  telephone
                });
                clearCurrentSurveyQuestion();
                clearSurveyResults();
                navigate(
                  getVoterLinkForFlowType({
                    town,
                    streetName,
                    address1,
                    address2,
                    firstname,
                    surname
                  })
                );
              }}
            >
              {NameWithVI(`${firstname} ${surname}`, dob, gender)}
            </Button>
          )
        )}
      </ButtonList>
      <h1>{iln.gettext("Neighbouring Households")}</h1>
      <ButtonList className={"Location-list"}>
        {getNeighbouringHouseholds(households, householdIndex)
          .filter(
            ({ address: { address1 } }) =>
              address1 !== household.address.address1
          )
          .map((household, idx) => {
            const {
              address: { address1, address2, streetName, town }
            } = household;

            return (
              <HouseholdButton
                key={idx}
                household={household}
                onHouseholdSelect={(household) => {
                  const flowType = getFlowType();

                  if (flowType === "gps") {
                    navigate(
                      `/street/${town}/${streetName}/${
                        address2 ? address1 + " " + address2 : address1
                      }`,
                      { state: location.state }
                    );
                  }

                  if (flowType === "search") {
                    setSelectedGHousehold(household);
                    navigate(
                      `/addressSearch/${
                        address2 ? address1 + " " + address2 : address1
                      }`
                    );
                  }
                }}
              />
            );
          })}
      </ButtonList>
    </React.Fragment>
  );
};

HouseholdPage.propTypes = propTypes;

export default HouseholdPage;
