import "./styles.css";

import React, { useContext, useEffect, useState } from "react";

import Button from "components/common/Button";
import ErrorText from "components/common/ErrorText";
import Page from "components/app/Page";
import { TranslationContext } from "context/Translation";
import { getHadLocationPermissionBefore } from "helpers/localStorage";
import propTypes from "./propTypes";
import { useNavigate } from "react-router-dom";

const getErrorText = ({ code }) => {
  switch (code) {
    case -1:
      return "Your device does not support geolocation";
    case 1:
      return "Location access denied, you may need to check your browser's settings for access to be requested again";
    case 2:
      return "Location access granted, but your device was unable to obtain your current location";
    case 3:
      return "Obtaining geolocation information timed out, please try again";
    default:
      return "Unknown Error";
  }
};

const PermissionsPage = ({ requestPermissions }) => {
  const iln = useContext(TranslationContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const onSuccess = () => navigate("/streets");
  const onFailure = (error) => setErrorMessage(getErrorText(error));

  useEffect(() => {
    if (getHadLocationPermissionBefore()) onSuccess();
  }, []);

  return (
    <Page className="Permissions-page">
      <p>
        {iln.gettext(
          "In order to function VoterID needs access to your current position (geolocation)"
        )}
      </p>
      <p>
        {iln.gettext("Tap the")}{" "}
        <strong>{iln.gettext("Begin Door Knocking")}</strong>{" "}
        {iln.gettext("button below and ensure that you tap")}{" "}
        <strong>{iln.gettext("Allow")}</strong>{" "}
        {iln.gettext("when asked if you wish for")}{" "}
        <strong>{window.location.host}</strong>{" "}
        {iln.gettext("to access your current location")}
      </p>
      <ErrorText className={"errorTextLocation"}>{errorMessage}</ErrorText>
      <div className="Begin-knocking-button-wrapper">
        <Button onClick={() => requestPermissions(onSuccess, onFailure)}>
          Begin Door Knocking
        </Button>
      </div>
    </Page>
  );
};

PermissionsPage.propTypes = propTypes;

export default PermissionsPage;
