import "./styles.css";

import React, { useContext } from "react";

import Button from "components/common/Button";
import { DebugModeContext } from "context/DebugMode/DebugMode";
import HouseholdCount from "./HouseholdCount";
import { TranslationContext } from "context/Translation";
import classnames from "helpers/classnames";
import distanceBetweenLatLngs from "helpers/distanceBetweenLatLngs";
import getLastVisitedString from "./helpers/getLastVisitedString";
import propTypes from "./propTypes";

const HouseholdButton = ({ household, onHouseholdSelect, position }) => {
  const iln = useContext(TranslationContext);
  const { debug } = useContext(DebugModeContext);
  const {
    visited,
    lastVisited,
    answered,
    address: { address1, address2, town },
    constituents: occupants
  } = household;

  return (
    <Button onClick={() => onHouseholdSelect(household)}>
      <div className={visited ? "Household-visited" : undefined}>
        {`${address1}${address2 ? ", " + address2 + ", " : ", "}${town}`}
      </div>
      {answered !== null ? (
        <div className={classnames("Visited-date", "Household-visited")}>
          {lastVisited
            ? getLastVisitedString(lastVisited, iln, iln)
            : answered === false
            ? "Did not answer"
            : "Visited"}
        </div>
      ) : (
        <HouseholdCount numberOfOccupants={occupants.length} />
      )}
      {debug && position && (
        <div>
          {(
            distanceBetweenLatLngs(
              position.lat,
              position.lng,
              household.lat,
              household.lng
            ) * 1000
          ).toFixed(2)}
          m
        </div>
      )}
    </Button>
  );
};

HouseholdButton.propTypes = propTypes;

export default HouseholdButton;
