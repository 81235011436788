import { household } from "propTypes/household.propType";
import { position } from "propTypes/position.propType";
import PropTypes from "prop-types";

const propTypes = {
  household: household,
  onHouseholdSelect: PropTypes.func.isRequired,
  position: position
};

export default propTypes;
