import "./styles.css";

import React, { useContext } from "react";

import Button from "components/common/Button";
import ContactDetailForm from "../common/ContactDetailForm";
import { TranslationContext } from "context/Translation";
import { isEmail } from "helpers/isEmail";
import { phoneNumberToInternational } from "../common/helpers/convertPhoneNumbers";
import propTypes from "./propTypes";
import useContactDetailsState from "../common/hooks/useContactDetailsState";

const ContactDetailCollection = ({
  onConfirmClick,
  existingContactDetails,
  onBackClick
}) => {
  const iln = useContext(TranslationContext);

  const [contactDetails, setContactDetails] = useContactDetailsState(
    existingContactDetails
  );

  return (
    <React.Fragment>
      <ContactDetailForm state={contactDetails} setState={setContactDetails} />
      <div className="Buttons-wrapper">
        {onBackClick ? (
          <Button onClick={onBackClick}>{iln.gettext("Back")}</Button>
        ) : (
          <div />
        )}
        <Button
          disabled={
            contactDetails.email.trim() !== "" && !isEmail(contactDetails.email)
          }
          onClick={() =>
            onConfirmClick({
              email: contactDetails.email,
              telephone: phoneNumberToInternational(contactDetails.telephone),
              mobile: phoneNumberToInternational(contactDetails.mobile)
            })
          }
        >
          {iln.gettext("Confirm")}
        </Button>
      </div>
    </React.Fragment>
  );
};

ContactDetailCollection.propTypes = propTypes;

export default ContactDetailCollection;
