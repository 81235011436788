import React, { useContext } from "react";

import Household from "components/Household";
import Page from "../../app/Page";
import { TranslationContext } from "context/Translation";
import {
  getCurrentHouseholds,
  getSelectedHousehold
} from "../../../helpers/localStorage";
import { useLocation, useNavigate } from "react-router-dom";

const HouseholdFromSearch = () => {
  const iln = useContext(TranslationContext);
  const location = useLocation();
  const navigate = useNavigate();

  const streetNameFromUrl = decodeURIComponent(
    location.pathname.split("/").pop()
  );

  const household = getSelectedHousehold();
  const households = getCurrentHouseholds();

  const {
    address: { address1, address2 }
  } = household;
  const streetNameFromLocalStorage = address2
    ? address1 + " " + address2
    : address1;

  if (streetNameFromLocalStorage !== streetNameFromUrl) {
    navigate("/addressSearch");
  }

  if (household && households) {
    return (
      <Page
        header={household?.address?.address1 || iln.gettext("Household")}
        breadCrumb
      >
        <Household household={household} households={households} />
      </Page>
    );
  }
};

export default HouseholdFromSearch;
