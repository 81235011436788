import ContactDetailCollection from "./ContactDetailCollection";
import MultipleChoice from "./MultipleChoice";
import OpenText from "./OpenText";
import React from "react";
import SingleChoice from "./SingleChoice";
import { TranslationContext } from "context/Translation";
import UnknownType from "./UnknownType";
import propTypes from "./propTypes";
import shuffleArray from "./helpers/shuffleArray";
import { useContext } from "react";

const isTrue = (value) => value === "true" || value === true;

const getQuestion = ({
  questionType,
  next,
  back,
  questionNumber,
  answers,
  contactDetails,
  randomise,
  pop,
  caseType,
  iln,
  action
}) => {
  if (isTrue(randomise)) {
    if (isTrue(pop)) {
      var lastAnswer = answers.pop();
    }
    answers = shuffleArray(answers);
    if (isTrue(pop)) {
      answers.push(lastAnswer);
    }
  }

  const onBackClick = questionNumber > 1 ? back : undefined;
  switch (questionType) {
    case "singleChoice":
    case "VI":
    case "VISqueeze":
      return (
        <SingleChoice
          answers={answers}
          onAnswerClick={({ nextQuestion, answerID }) =>
            next({
              nextQuestion,
              response: { question: questionNumber, answer: answerID }
            })
          }
          onBackClick={onBackClick}
        />
      );
    case "multipleChoice":
      return (
        <MultipleChoice
          answers={answers}
          onConfirmClick={(selected) =>
            next({
              nextQuestion: answers[0].revealQuestion,
              response: {
                question: questionNumber,
                answer: selected
              }
            })
          }
          onBackClick={onBackClick}
        />
      );
    case "dataCollection":
      return (
        <ContactDetailCollection
          existingContactDetails={contactDetails}
          onConfirmClick={(contactDetails) =>
            next({
              nextQuestion: answers[0].revealQuestion,
              response: {
                question: questionNumber,
                answer: 1,
                contactDetails
              }
            })
          }
          onBackClick={onBackClick}
        />
      );
    case "openText":
      return (
        <OpenText
          key={questionNumber}
          existingContactDetails={contactDetails}
          includeCaseTypeSelect={
            action === "createCase" && caseType === "doorknocker-decides"
          }
          onConfirmClick={({ answer, contactDetails, selectedCaseType }) =>
            next({
              nextQuestion: questionNumber + 1,
              response: {
                question: questionNumber,
                answer,
                contactDetails,
                caseType:
                  caseType === "doorknocker-decides"
                    ? selectedCaseType
                    : caseType
              }
            })
          }
          onBackClick={onBackClick}
          onSkipClick={({ contactDetails }) =>
            next({
              nextQuestion: questionNumber + 1,
              response: {
                question: questionNumber,
                answer: iln.gettext("Voter skipped question"),
                contactDetails
              }
            })
          }
        />
      );
    default:
      return (
        <UnknownType
          onBackClick={onBackClick}
          onSkipClick={next({
            nextQuestion: questionNumber + 1,
            response: {
              question: questionNumber,
              answer: "Skipped - Unknown Question Type",
              contactDetails
            }
          })}
        />
      );
  }
};

const QuestionTypes = ({
  questionType,
  questionNumber,
  answers,
  next,
  contactDetails,
  randomise,
  pop,
  caseType,
  back,
  action
}) => {
  const iln = useContext(TranslationContext);

  return getQuestion({
    questionType,
    next,
    back,
    questionNumber,
    answers,
    contactDetails,
    randomise,
    pop,
    caseType,
    iln,
    action
  });
};

QuestionTypes.propTypes = propTypes;

export default QuestionTypes;
