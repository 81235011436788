import "./styles.css";

import React, { useContext } from "react";

import Button from "components/common/Button";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";

const UnknownType = ({ onSkipClick, onBackClick }) => {
  const iln = useContext(TranslationContext);

  return (
    <div className="Unknown-question">
      <p>{iln.gettext("Unknown question type")}</p>

      <div className="Buttons-wrapper">
        {onBackClick ? (
          <Button className="Back-button" onClick={onBackClick}>
            {iln.gettext("Back")}
          </Button>
        ) : (
          <div />
        )}
        <Button className="Back-button" onClick={onSkipClick}>
          {iln.gettext("Skip")}
        </Button>
      </div>
    </div>
  );
};

UnknownType.propTypes = propTypes;

export default UnknownType;
