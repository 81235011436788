import React, { useContext } from "react";

import Select from "components/common/Select/index.js";
import { TranslationContext } from "context/Translation/index.js";
import { getCaseTypes } from "helpers/localStorage.js";
import propTypes from "./CaseTypeSelect.propTypes.js";

const CaseTypeSelect = ({ ...props }) => {
  const iln = useContext(TranslationContext);
  const caseTypes = getCaseTypes();

  return (
    <Select {...props}>
      {(caseTypes && caseTypes?.length > 0
        ? caseTypes
        : [{ id: -1, caseType: iln.gettext("No issue types available") }]
      ).map(({ id, caseType }) => (
        <option key={id} value={id}>
          {caseType}
        </option>
      ))}
    </Select>
  );
};

CaseTypeSelect.propTypes = propTypes;

export default CaseTypeSelect;
