/**
 * Store an object against a key string.
 * @param {String} name - The string key that the object will be stored against.
 * @param {Object} object - The object to be stored in local storage.
 */
const setItem = (name, object) =>
  localStorage.setItem(name, JSON.stringify(object));

/**
 * Gets the object stored against the key.
 * @param {String} name - The key used to look up localStorage.
 * @returns {Object} - The object stored against that key or null if nothing is present.
 */
const getItem = (name) => JSON.parse(localStorage.getItem(name));

/*
 * Session token
 */
const tokenKey = "token";
export const setToken = (payload) => setItem(tokenKey, payload);
export const getToken = () => getItem(tokenKey);
export const clearToken = () => localStorage.removeItem(tokenKey);

/**
 * Previous Location Permission setter & getter
 */
const hadLocationPermissionBeforeKey = "hadLocationPermissionBefore";
export const setHadLocationPermissionBefore = (payload) =>
  setItem(hadLocationPermissionBeforeKey, payload);
export const getHadLocationPermissionBefore = () =>
  getItem(hadLocationPermissionBeforeKey);

/*
 * Survey
 */
const surveyKey = "survey";
export const setSurvey = (payload) => setItem(surveyKey, payload);
export const getSurvey = () => getItem(surveyKey);

/*
 * Parties
 */
const votingIntentionsKey = "votingIntentions";
export const setVotingIntentions = (payload) =>
  setItem(votingIntentionsKey, payload);
export const getVotingIntentions = () => getItem(votingIntentionsKey);

/*
 * Voter
 */
const voterKey = "voter";
export const setVoter = (payload) => setItem(voterKey, payload);
export const getVoter = () => getItem(voterKey);

/*
 * Current Survey Question
 */
const currentSurveyQuestionKey = "currentSurveyQuestion";
export const setCurrentSurveyQuestion = (payload) =>
  setItem(currentSurveyQuestionKey, payload);
export const getCurrentSurveyQuestion = () => getItem(currentSurveyQuestionKey);
export const clearCurrentSurveyQuestion = () =>
  localStorage.removeItem(currentSurveyQuestionKey);

/*
 * Survey Responses
 */
const surveyResultsKey = "surveyResults";
export const setSurveyResults = (payload) => setItem(surveyResultsKey, payload);
export const getSurveyResults = () => getItem(surveyResultsKey);
export const clearSurveyResults = () =>
  localStorage.removeItem(surveyResultsKey);

/*
 * Sort Options
 */
const sortOptionsKey = "sortOptions";
export const setSortOptions = (payload) => setItem(sortOptionsKey, payload);
export const getSortOptions = () => getItem(sortOptionsKey);

/*
 * Last street position
 */
const lastHouseholdSelectScrollKey = "lastHouseholdSelectScroll";
export const setLastHouseholdSelectScroll = (payload) =>
  setItem(lastHouseholdSelectScrollKey, payload);
export const getLastHouseholdSelectScroll = () =>
  getItem(lastHouseholdSelectScrollKey);
export const clearLastHouseholdSelectScroll = () =>
  localStorage.removeItem(lastHouseholdSelectScrollKey);

/*
 * Selected street
 */
const selectedStreetKey = "selectedStreet";
export const setSelectedStreet = (payload) =>
  setItem(selectedStreetKey, payload);
export const getSelectedStreet = () => getItem(selectedStreetKey);

/*
 * Selected town
 */
const selectedTownKey = "selectedTown";
export const setSelectedTown = (payload) => setItem(selectedTownKey, payload);
export const getSelectedTown = () => getItem(selectedTownKey);

/*
 * Selected household
 */
const selectedHouseholdKey = "selectedHousehold";
export const setSelectedGHousehold = (payload) =>
  setItem(selectedHouseholdKey, payload);
export const getSelectedHousehold = () => getItem(selectedHouseholdKey);

/*
 * Selected household
 */
const currentHouseholdsKey = "currentHouseholds";
export const setCurrentHouseholds = (payload) =>
  setItem(currentHouseholdsKey, payload);
export const getCurrentHouseholds = () => getItem(currentHouseholdsKey);

/*
 * Locale
 */
const localeKey = "locale";
export const setLocale = (payload) => setItem(localeKey, payload);
export const getLocale = () => getItem(localeKey);

/*
 * Case types
 */
const caseTypesKey = "caseTypes";
export const setCaseTypes = (payload) => setItem(caseTypesKey, payload);
export const getCaseTypes = () => getItem(caseTypesKey);

/*
 * Flow type
 */
const flowTypeKey = "flowType";
export const setFLowType = (payload) => setItem(flowTypeKey, payload);
export const getFlowType = () => getItem(flowTypeKey);
