import "./styles.css";

import React, { useContext, useState } from "react";

import Button from "components/common/Button";
import CaseTypeSelect from "./CaseTypeSelect";
import ContactDetailForm from "../common/ContactDetailForm";
import TextArea from "components/common/TextArea";
import { TranslationContext } from "context/Translation";
import classnames from "helpers/classnames";
import { isEmail } from "helpers/isEmail";
import { openTextMaxChars } from "consts/openTextMaxChars";
import { phoneNumberToInternational } from "../common/helpers/convertPhoneNumbers";
import propTypes from "./propTypes";
import useCaseTypeSelectState from "./CaseTypeSelect/hooks/useCaseTypeState";
import useContactDetailsState from "../common/hooks/useContactDetailsState";

const OpenText = ({
  onConfirmClick,
  onBackClick,
  onSkipClick,
  existingContactDetails,
  includeCaseTypeSelect
}) => {
  const iln = useContext(TranslationContext);

  const [value, setValue] = useState("");
  const [selectedCaseType, setSelectedCaseType] = useCaseTypeSelectState();
  const [contactDetails, setContactDetails] = useContactDetailsState(
    existingContactDetails
  );

  const overCharLimit = value.length > openTextMaxChars;

  return (
    <div className="Opentext-question">
      <style>
        {".Modal-card { height: 80vh; } .Survey { height: calc(100% - 80px) }"}
      </style>
      <ContactDetailForm state={contactDetails} setState={setContactDetails} />
      {includeCaseTypeSelect && [
        <div key="heading" className="Label">
          {iln.gettext("Issue Type")}
        </div>,
        <CaseTypeSelect
          key="select"
          value={selectedCaseType}
          onChange={({ target: { value } }) =>
            setSelectedCaseType(parseInt(value))
          }
          keepErrorSpacing
          className={{ error: "Email-collection-error" }}
        />
      ]}
      <div className="Label">{iln.gettext("Issue Description")}</div>
      <TextArea
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />
      <div
        className={classnames(
          "Opentext-char-count",
          overCharLimit && "Opentext-question-over-limt"
        )}
      >
        {overCharLimit && `${iln.gettext("Over character limit")} -`}{" "}
        {value.length} / {openTextMaxChars}
      </div>
      <div className="Buttons-wrapper">
        {onBackClick ? (
          <Button onClick={onBackClick}>{iln.gettext("Back")}</Button>
        ) : (
          <div />
        )}
        <div>
          <Button onClick={() => onSkipClick({ contactDetails })}>
            {iln.gettext("Skip")}
          </Button>{" "}
          <Button
            disabled={
              overCharLimit ||
              value.trim().length === 0 ||
              (contactDetails.email.trim() !== "" &&
                !isEmail(contactDetails.email))
            }
            onClick={() =>
              onConfirmClick({
                answer: value,
                contactDetails: {
                  email: contactDetails.email,
                  telephone: phoneNumberToInternational(
                    contactDetails.telephone
                  ),
                  mobile: phoneNumberToInternational(contactDetails.mobile)
                },
                selectedCaseType
              })
            }
          >
            {iln.gettext("Confirm")}
          </Button>
        </div>
      </div>
    </div>
  );
};

OpenText.propTypes = propTypes;

export default OpenText;
