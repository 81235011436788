import PropTypes from "prop-types";

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, text: PropTypes.string })
  ),
  open: PropTypes.bool.isRequired,
  onItemSelected: PropTypes.func.isRequired
};

export default propTypes;
