import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.shape({
    select: PropTypes.string,
    error: PropTypes.string
  }),
  errorText: PropTypes.string,
  keepErrorSpacing: PropTypes.bool
};

export default propTypes;
