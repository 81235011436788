import "./AddressSearch.css";

import React, { useState } from "react";

import InputSearchAutoComplete from "components/common/InputSearchAutoComplete/InputSearchAutoComplete";
import Page from "components/app/Page";
import Spinner from "components/common/Spinner";
import api from "api";
import { useNavigate } from "react-router-dom";
import {
  setCurrentHouseholds,
  setSelectedGHousehold
} from "../../helpers/localStorage";

const formatHouseholdsAsDisplayItems = (households) => {
  return households.reduce((acc, household) => {
    if (!household?.address) return acc;
    const { streetNumber, streetName, town } = household.address;
    const addressDisplayText = `${streetNumber} ${streetName}, ${town}`;
    return [...acc, { ...household, text: addressDisplayText }];
  }, []);
};

const AddressSearch = () => {
  const navigate = useNavigate();
  const [loadingHousehold, setLoadingHousehold] = useState(false);
  const searchAddresses = async (searchTerm, signal) => {
    const { households } = await api.searchStreet({
      address: searchTerm,
      signal
    });
    return formatHouseholdsAsDisplayItems(households);
  };

  const handleHouseholdSelected = async (household) => {
    setLoadingHousehold(true);

    const {
      lat,
      lng,
      address: { address1, address2 }
    } = household;

    const { households } = await api.getNearbyHouseholds({
      lat,
      lng
    });

    setSelectedGHousehold(household);
    setCurrentHouseholds(households);

    navigate(
      `/addressSearch/${address2 ? address1 + " " + address2 : address1}`
    );
  };

  return (
    <React.Fragment>
      {loadingHousehold && (
        <div className="Search-street-overlay">
          <div className="Search-street-spinner-container">
            <Spinner size={"large"} />
            <h3>Loading Household</h3>
          </div>
        </div>
      )}
      <Page header={"Address Search"} breadCrumb>
        <h1>Electoral Roll Address Search</h1>
        <InputSearchAutoComplete
          onItemSelected={(household) => handleHouseholdSelected(household)}
          name="searchHouseholds"
          dataSource={searchAddresses}
          keepErrorSpacing
        />
        <p>
          This address search will only display households containing one or
          more registered voters
        </p>
      </Page>
    </React.Fragment>
  );
};
export default AddressSearch;
