import React, { useContext, useLayoutEffect } from "react";
import {
  getLastHouseholdSelectScroll,
  setLastHouseholdSelectScroll
} from "helpers/localStorage";

import ButtonList from "components/common/ButtonList";
import HouseholdButton from "components/HouseholdSelect/HouseholdButton";
import Placeholder from "components/common/Placeholder";
import { TranslationContext } from "context/Translation";
import propTypes from "./propTypes";
import { useNavigate, useNavigationType } from "react-router-dom";

const scrollToLastPosition = () => {
  const lastScroll = getLastHouseholdSelectScroll();

  lastScroll &&
    window.scrollTo({
      top: getLastHouseholdSelectScroll()
    });
};

const HouseholdList = ({ streetName, households, position }) => {
  const iln = useContext(TranslationContext);

  const navigationType = useNavigationType();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    scrollToLastPosition(navigationType);
  }, []);

  const handleHouseHoldSelect = (household) => {
    const {
      address: { address1, address2, town }
    } = household;

    navigate(
      `/street/${town}/${streetName}/${
        address2 ? address1 + " " + address2 : address1
      }`,
      { state: { from: window.location.pathname } }
    );
    setLastHouseholdSelectScroll(window?.scrollY);
  };

  return (
    <React.Fragment>
      <p>
        {iln.gettext(
          "Nearby households on %1 with registered voters",
          streetName
        )}
      </p>
      {!households ? (
        <Placeholder width="100%" height="calc(100vh - 250px)" />
      ) : (
        <ButtonList className="Location-list">
          {households.map((household, idx) => (
            <HouseholdButton
              key={idx}
              onHouseholdSelect={(household) =>
                handleHouseHoldSelect(household)
              }
              household={household}
              position={position}
            />
          ))}
        </ButtonList>
      )}
    </React.Fragment>
  );
};

HouseholdList.propTypes = propTypes;

export default HouseholdList;
