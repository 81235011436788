const { useState } = require("react");

import {
  mobileNumberToLocal,
  telephoneNumberToLocal
} from "../helpers/convertPhoneNumbers";

const useContactDetailsState = (existingDetails) =>
  useState({
    email: existingDetails?.email || "",
    telephone: telephoneNumberToLocal(existingDetails?.telephone || ""),
    mobile: mobileNumberToLocal(existingDetails?.mobile || "")
  });

export default useContactDetailsState;
