import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.shape({
    input: PropTypes.string,
    container: PropTypes.string,
    dropdown: PropTypes.string,
    error: PropTypes.string
  }),
  keepErrorSpacing: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  onItemSelected: PropTypes.func.isRequired
};

export default propTypes;
